import C from "./HeroBlock.module.css";

import type { ReactNode } from "react";
import { useTitle } from "../utils/react/useTitle.ts";
import { ContentPane } from "./ContentPane.tsx";
import { MaskIcon } from "./icon/MaskIcon.tsx";

export function HeroBlock({ children, title, icon }: { children: ReactNode; title: string; icon?: string }) {
    useTitle(title);

    return (
        <ContentPane>
            <div className={C.HeroBlock}>
                <h1 className="pb-3">{title}</h1>
                {children}
                {icon ? (
                    <div className={C.HeroIcon}>
                        <MaskIcon className={`${icon} size-100%`} />
                    </div>
                ) : null}
            </div>
        </ContentPane>
    );
}
