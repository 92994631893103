import type { BillingAccountCardData } from "@warrenio/api-spec/spec.oats.gen";
import type { PaymentMethodId } from "./PaymentMethod.tsx";

/* Enumerate all the classes here (as literals) so UnoCSS can properly generate them */
const cardTypeIconClasses: Record<string, string> = {
    _default: "jp-creditcards-icon mask-icon",
    "by invoice": "jp-icon-text-editor mask-icon",

    alipay: "jp-alipay-icon bg-icon",
    amex: "jp-amex-icon bg-icon",
    diners: "jp-diners-icon bg-icon",
    discover: "jp-discover-icon bg-icon",
    elo: "jp-elo-icon bg-icon",
    hipercard: "jp-hipercard-icon bg-icon",
    jcb: "jp-jcb-icon bg-icon",
    maestro: "jp-maestro-icon bg-icon",
    mastercard: "jp-mastercard-icon bg-icon",
    paypal: "jp-paypal-icon bg-icon",
    unionpay: "jp-unionpay-icon bg-icon",
    visa: "jp-visa-icon bg-icon",
};
const methodTypeIconClasses: Partial<Record<PaymentMethodId | "_default", string>> = {
    _default: "jp-creditcards-icon",

    invoice: "jp-icon-text-editor",

    stripe_creditcard: "jp-creditcards-icon",
    omise_creditcard: "jp-creditcards-icon",

    "duitku::A1": "jp-duitku-a1-icon",
    "duitku::M1": "jp-duitku-m1-icon",
    "duitku::BK": "jp-duitku-bk-icon",
    "duitku::BC": "jp-duitku-bc-icon",
    "duitku::I1": "jp-duitku-i1-icon",
    "duitku::B1": "jp-duitku-b1-icon",
    "duitku::VC": "jp-duitku-vc-icon",
    "duitku::VA": "jp-duitku-va-icon",
    "duitku::OV": "jp-duitku-ov-icon",
    "duitku::BT": "jp-duitku-bt-icon",
    "duitku::FT": "jp-duitku-ft-icon",
    "duitku::SP": "jp-duitku-sp-icon",
    "duitku::DA": "jp-duitku-da-icon",
    "duitku::AG": "jp-duitku-ag-icon",
    "duitku::S1": "jp-duitku-s1-icon",
    "duitku::BR": "jp-duitku-br-icon",
    "duitku::NC": "jp-duitku-nc-icon",

    "adyen::yandex": "jp-adyen-yandex-icon",
    "stripe_wallet::grabpay": "jp-grabpay-icon",
    "stripe_wallet::wechat_pay": "jp-wechatpay-icon",
    "stripe_wallet::alipay": "jp-omise-alipay-icon",

    "clictopay::clictopay": "jp-clictopay-icon",

    "omise::rabbit_linepay": "jp-omise-linepay-icon",
    "omise::internet_banking_bbl": "jp-omise-bangok-bank-icon",
    "omise::alipay": "jp-omise-alipay-icon",
    "omise::truemoney": "jp-omise-truemoney-icon",
    "omise::internet_banking_bay": "jp-omise-ayudhya-icon",
    "omise::promptpay": "jp-omise-promptpay-icon",

    "sslcommerz::sslcommerz": "jp-sslcommerz-sslcommerz-icon",
    "sslcommerz::othercard": "jp-sslcommerz-othercard-icon",
    "sslcommerz::internetbank": "jp-sslcommerz-internetbank-icon",
    "sslcommerz::mobilebank": "jp-sslcommerz-mobilebank-icon",
    "sslcommerz::brac_visa": "jp-sslcommerz-bracvisa-icon",
    "sslcommerz::dbbl_visa": "jp-sslcommerz-dbblvisa-icon",
    "sslcommerz::city_visa": "jp-sslcommerz-cityvisa-icon",
    "sslcommerz::ebl_visa": "jp-sslcommerz-eblvisa-icon",
    "sslcommerz::sbl_visa": "jp-sslcommerz-sblvisa-icon",
    "sslcommerz::visacard": "jp-sslcommerz-visacard-icon",
    "sslcommerz::brac_master": "jp-sslcommerz-bracmaster-icon",
    "sslcommerz::dbbl_master": "jp-sslcommerz-dbblmaster-icon",
    "sslcommerz::city_master": "jp-sslcommerz-citymaster-icon",
    "sslcommerz::ebl_master": "jp-sslcommerz-eblmaster-icon",
    "sslcommerz::sbl_master": "jp-sslcommerz-sblmaster-icon",
    "sslcommerz::mastercard": "jp-sslcommerz-mastercard-icon",
    "sslcommerz::city_amex": "jp-sslcommerz-cityamex-icon",
    "sslcommerz::amexcard": "jp-sslcommerz-amexcard-icon",
    "sslcommerz::qcash": "jp-sslcommerz-qcash-icon",
    "sslcommerz::dbbl_nexus": "jp-sslcommerz-dbblnexus-icon",
    "sslcommerz::bankasia": "jp-sslcommerz-bankasia-icon",
    "sslcommerz::abbank": "jp-sslcommerz-abbank-icon",
    "sslcommerz::ibbl": "jp-sslcommerz-ibbl-icon",
    "sslcommerz::mtbl": "jp-sslcommerz-mtbl-icon",
    "sslcommerz::bkash": "jp-sslcommerz-bkash-icon",
    "sslcommerz::dbblmobilebanking": "jp-sslcommerz-dbblmobilebanking-icon",
    "sslcommerz::city": "jp-sslcommerz-city-icon",
    "sslcommerz::upay": "jp-sslcommerz-upay-icon",
    "sslcommerz::tapnpay": "jp-sslcommerz-tapnpay-icon",
};

export const defaultCardIconClass = "jp-card-icon mask-icon text-primary";

export function getCardIconClass(card: BillingAccountCardData | null | undefined) {
    if (card) {
        const key = card.card_type || card.brand;
        const icon = cardTypeIconClasses[key ? key.toLowerCase() : "_default"];
        return `${icon} text-primary`;
    }

    return defaultCardIconClass;
}

export function getMethodLinkIconClass(key: PaymentMethodId | null | undefined, isSelected = false) {
    if (key) {
        const icon = methodTypeIconClasses[key ?? "_default"];
        return isSelected ? `${icon} mask-icon text-primary` : `${icon} bg-icon text-primary`;
    }

    return defaultCardIconClass;
}
