import { queryOptions, type QueryClient, type QueryKey } from "@tanstack/react-query";
import type { BillingAccountCard, BillingAccountCardData } from "@warrenio/api-spec/spec.oats.gen";
import * as billingSpec from "@warrenio/api-spec/spec/billing";
import { atomFamily } from "jotai/utils";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { mutationOptions } from "../../utils/query/runMutation.ts";
import type { ApiClient } from "../api/apiClient";
import { getResponseData } from "../api/apiClient";
import { toastOptions } from "../compute/vmQuery.ts";
import { raiseRequestToast } from "../notifications/toast.tsx";

export type Response = BillingAccountCard[];

export interface Params {
    billing_account_id: number;
}

export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["payment/card/list"] : ["payment/card/list", params.billing_account_id];
}

export async function cardListRequest(apiClient: ApiClient, billing_account_id: number, signal: AbortSignal) {
    return getResponseData(
        await apiClient.GET("/payment/card/list", {
            signal,
            params: { query: { billing_account_id } },
        }),
    );
}

export function getQuery(client: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<Response> => {
            const { billing_account_id } = params;
            return await cardListRequest(client, billing_account_id, signal);
        },
    });
}

export const baCardQueryAtom = atomFamily((billing_account_id: number) =>
    atomFromStandardQueryOptions(getQuery, { billing_account_id }),
);

export function parseCardAdditionalData({ additional_data }: BillingAccountCard): BillingAccountCardData | undefined {
    if (!additional_data) {
        return undefined;
    }

    const data = JSON.parse(additional_data) as BillingAccountCardData;
    if (import.meta.env.DEV) {
        // Validate
        billingSpec.BillingAccountCardData.parse(data);
    }
    return data;
}

export function deletePaymentCardMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["payment/card/delete"],
        async mutationFn({ payment_object_id }: { payment_object_id: number }) {
            return getResponseData(
                await api.DELETE("/payment/card", {
                    params: { query: { payment_object_id } },
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Payment Card deleted",
                error: "Error deleting Payment Card",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey() });
        },
    });
}

export function setPaymentCardAsPrimaryMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["payment/card/set_primary"],
        async mutationFn({ payment_object_id }: { payment_object_id: number }) {
            return getResponseData(
                await api.PUT("/payment/card/set_primary", {
                    params: { query: { payment_object_id } },
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Payment Card set as primary",
                error: "Error setting Payment Card as primary",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey() });
        },
    });
}
